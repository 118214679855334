<template>
	<div class="flex md12">
		<meta name="viewport" content="width=device-width, initial-scale=1.0">
		<div :style="topScaleRatio">
			<div class="cus_home_container">
	      <div class="cus_home_screen">
	        <div class="cus_Dcompany_logo"><img class="cus_Dlogo" src="@/assets/infyn.png" /></div>
	        <img src="@/assets/tv.png" class="cus_min_max">
          <div class="cus_transparentImage"></div>
	        <video
            class="cus_image2"
            :src="homeVideoPath"
            autoplay
            loop
            muted
            playsinline
          />
          <video
            v-if="loaderHide"
            class="cus_pageLoader"
            src="@/assets/FinalVideoLoader.mp4"
            autoplay
            loop
            muted
            playsinline
          />
          <img class="cus_menuLogo" src="@/assets/menu.png" />
          <img class="cus_tvInCompnayLogo" :src="brandLogo" />
          <img class="cus_tvInBrandLogo" src="@/assets/powered_by_infynect.png" />
          <span class="cus_guest_name"><b>{{guest_name}}</b></span>
          <span class="cus_WelcomeMsg">{{welcomeMsg}}</span>
          <img class="cus_kitchen_label" src="@/assets/label.png" />
          <img class="cus_kitchenLogo" src="@/assets/cooking.png" />
          <img class="cus_timeLogo" src="@/assets/time.png" />
          <span class="cus_timetextTag">Time</span>
          <span class="cus_timeTag">{{on_time}}</span>
          <span class="cus_kitchentextTag">Kitchen Closes</span>
          <span class="cus_kitchentimeTag">{{kitchen_close}}</span>
          <img class="cus_lineset" src="@/assets/line.png" />
          <div>
            <span class="cus_labelTag">Food</span>
            <div class="cus_foodlisttag">
              <div>
                <a v-for="food in foodLists">
                  <img class="cus_imageTile ml-2" :src="food.image_path" />
                </a>
              </div>
            </div>
            <span class="cus_locationLabel">Places you must visit</span>
            <div class="cus_locationlisttag">
              <div>
                <a v-for="location in locationLists">
                  <img class="cus_imageTile ml-2" :src="location.image_path" />
                </a>
              </div>
            </div>
          </div>

	        <div class="cus_home_text">Create an immersive in-room experience with Hepi</div>
	        <div class="cus_home_text2">make your guest feel extra special</div>
	        <div class="buttonSet">
	          <button class="cus_button_tag" @click="modal('branding')" style="border: white; color: black;">Edit</button>
	          <button
	            class="cus_button_tag"
	            style="background-color: #f67218; border: #f67218; color: white;"
	            @click="modal('shipping')">Order Demo Kit</button>
	        </div>
	        <div>
	        	<img src="@/assets/roundedCustomization.png" style="width: 797px; margin-top: 12px; position: relative;" />
	        	<lable class="cus_label" style="left: 54px;">{{brand}}</lable>
	        	<lable class="cus_label" style="left: 216px;">{{food}}</lable>
	        	<lable class="cus_label" style="left: 388px;">{{location}}</lable>
	        	<lable class="cus_label" style="left: 556px;">{{service}}</lable>
	        	<lable class="cus_label" style="left: 717px;">Completed</lable>
	        </div>
	      </div>
	    </div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
  import vueResource from 'vue-resource';
  import config from '../../i18n/en.json';
  import ScreenSizeDetector from 'screen-size-detector';
  Vue.use(vueResource)

  export default {
    created () {
    	var screenSize=new ScreenSizeDetector().width;
	    var scaleRatioNumber=screenSize<600 ? (0.00125*screenSize) : 1;
	    this.topScaleRatio="zoom: "+scaleRatioNumber+";";
      const myTimer = () => {
        var date = new Date(), 
            noon = (date.getHours() >= 12) ? 'PM' : 'AM',
            hour = ((date.getHours()) > 12) ? ('0'+(date.getHours() - 12)) : date.getHours(),
            mins = ((date.getMinutes()) < 10) ? ('0'+(date.getMinutes())) : date.getMinutes();
        this.on_time = hour + ":" + mins + " " + noon;
      }
      setInterval(myTimer, 1000);
    	this.$http.get(config.menu.host + 'content/count').then(response => {
    		if(response.body.length > 0){
    			this.food=(response.body[0].food > 0) ? response.body[0].food+' Uploaded' : 'Completed';
    			this.brand=(response.body[0].branding > 0) ? response.body[0].branding+' Uploaded' : 'Completed';
    			this.location=(response.body[0].location > 0) ? response.body[0].location+' Uploaded' : 'Completed';
    			this.service=(response.body[0].service > 0) ? response.body[0].service+' Uploaded' : 'Completed';
    		}
        this.getContents();
    	})
    },
    data () {
    	return {
    		food: '',
    		service: '',
    		brand: '',
    		location: '',
    		topScaleRatio: '',
        foodLists: [],
        serviceLists: [],
        loaderHide: true,
        brandLogo: '',
        guest_name: '',
        welcomeMsg: '',
        homeVideoPath: '',
        kitchen_close: '',
        on_time: ''
    	}
    },
    methods: {
    	modal(type){
    		this.$router.push({ name: type });
    	},
      getContents() {
        this.$http.get(config.menu.host + 'content/user').then(user => {
          this.guest_name = 'HI '+user.body[0].full_name.toUpperCase();
          this.$http.get(config.menu.host + 'content/branding').then(responseBrand => {
            this.brandLogo = responseBrand.body[0].logo_path;
            this.welcomeMsg = responseBrand.body[0].intro_message;
            this.homeVideoPath = responseBrand.body[0].video_path;
            this.kitchen_close = responseBrand.body[0].kitchen_close;
            this.$http.get(config.menu.host + 'content/food').then(responseData => {
              this.foodLists = responseData.body;
              this.$http.get(config.menu.host + 'content/location').then(responseData => {
                this.locationLists = responseData.body;
                setTimeout(() => {
                  this.loaderHide = false;
                }, 2000);
              });
            });
         });
        });
      }
    }
  }
</script>

<style>
  body {
    background-image: url("../../assets/new_repeat.jpg");
    background-position: left top;
    background-repeat: repeat;
    background-size: 10px;
  }

  .cus_home_container {
    width: 800px;
    margin: 0 auto;
  }

  button:disabled {
    opacity: 0.4;
    cursor: default;
  }

  input:required {
    border: red solid 1px;
  }

  button {
    cursor: pointer;
  }

  .cus_foodlisttag::-webkit-scrollbar {
    display: none;
  }

  .cus_locationlisttag::-webkit-scrollbar {
    display: none;
  }

  .cus_min_max {
    width: 714px;
    margin-top: -95px;
    margin-bottom: -160px;
  }

  .cus_Dlogo {
    height: 130px;
    width: 130px;
  }

  .cus_home_screen {
    background-image: url("../../assets/circlebg.png");
    background-repeat: no-repeat;
    background-size: 743px;
    position: relative;
    height: 751px;
    width: 800px;
    text-align: center;
    background-position: center;
  }

  .cus_Dcompany_logo {
    margin-left: -614px;
    margin-bottom: -75px;
  }

  .cus_home_text {
    font-size: 20px;
    color: white;
    font-weight: 600;
    margin-top: -21px;
    text-size-adjust: 50%;
  }

  .cus_home_text2 {
    font-size: 20px;
    color: #8b8e94;
    text-size-adjust: 50%;
  }

  .buttonSet {
  }

  .video_screen {
    position: absolute;
    width: 607px;
    top: 131px;
    left: 95px;
  }

  .cus_button_tag {
    margin: 10px;
    padding: 15px 32px;
    font-size: 16px;
    width: 230px;
    border-radius: 10px;
    text-size-adjust: 50%;
  }

  .cus_label {
    position: absolute;
    top: 698px;
    color: #7b4d2d;
    font-weight: 600;
    font-size: 12px;
    text-size-adjust: 50%;
  }

  .cus_pageLoader {
    position: absolute;
    z-index: 10;
    left: 105px;
    height: 343px;
    width: 607px;
    top: 129px;
  }

  .cus_menuLogo {
    position: absolute;
    top: -6px;
    left: 234px;
    z-index: 5;
    width: 323px;
  }

  .cus_tvInCompnayLogo {
    position: absolute;
    top: 145px;
    left: 125px;
    z-index: 4;
    width: 50px;
    height: 50px;
  }

  .cus_tvInBrandLogo {
    position: absolute;
    width: 50px;
    height: 47px;
    z-index: 4;
    left: 180px;
    top: 146px;
  }

  .cus_guest_name {
    position: absolute;
    top: 190px;
    left: 126px;
    z-index: 3;
    height: 75px;
    color: #d96516;
    text-size-adjust: 50%;
  }

  .cus_WelcomeMsg {
    position: absolute;
    top: 214px;
    left: 126px;
    z-index: 3;
    width: 192px;
    font-size: 11px;
    color: #cccccc;
    text-align: left;
    white-space: normal;
    overflow: hidden;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-size-adjust: 50%;
  }

  .cus_transparentImage {
    position: absolute;
    top: 128px;
    left: 106px;
    background-image: url("../../assets/transparent11.png");
    width: 597px;
    height: 342px;
    z-index: 3;
  }

  .cus_image2 {
    position: absolute;
    top: 132px;
    left: 167px;
    width: 535px;
    height: 304px;
  }

  .cus_kitchen_label {
    position: absolute;
    top: 244px;
    left: 126px;
    z-index: 7;
    width: 180px;
    height: 123px;
  }

  .cus_kitchenLogo {
    position: absolute;
    top: 272px;
    left: 241px;
    z-index: 8;
    width: 21px;
  }

  .cus_kitchentextTag {
    position: absolute;
    top: 301px;
    left: 225px;
    z-index: 8;
    font-size: 10px;
    color: #cccccc;
    text-size-adjust: 50%;
  }

  .cus_kitchentimeTag {
    position: absolute;
    top: 316px;
    left: 232px;
    z-index: 9;
    font-size: 11px;
    color: #d96516;
    font-weight: 600;
    text-size-adjust: 50%;
  }

  .cus_timeLogo {
    position: absolute;
    top: 274px;
    left: 156px;
    z-index: 8;
    width: 20px;
  }

  .cus_lineset {
    position: absolute;
    top: 298px;
    left: 209px;
    height: 30px;
    z-index: 8;
  }

  .cus_timetextTag {
    position: absolute;
    top: 301px;
    left: 156px;
    z-index: 8;
    font-size: 10px;
    color: #cccccc;
    text-size-adjust: 50%;
  }

  .cus_timeTag {
    position: absolute;
    top: 316px;
    left: 146px;
    z-index: 9;
    font-size: 11px;
    color: #d96516;
    font-weight: 600;
    text-size-adjust: 50%;
  }

  .cus_foodlisttag {
    position: absolute;
    top: 358px;
    left: 119px;
    z-index: 9;
    overflow-x: scroll;
    scrollbar-width: none;
    white-space: nowrap;
    width: 583px;
  }

  .cus_imageTile {
    border-radius: 7px;
    width: 88px;
    height: 51px;
  }

  .cus_locationLabel {
    position: absolute;
    top: 419px;
    left: 129px;
    z-index: 9;
    font-size: 12px;
    color: #ffffff;
    text-size-adjust: 50%;
  }

  .cus_locationlisttag {
    position: absolute;
    top: 441px;
    left: 119px;
    z-index: 9;
    overflow: scroll;
    scrollbar-width: none;
    white-space: nowrap;
    width: 583px;
    height: 31px;
  }

  .cus_labelTag {
    position: absolute;
    top: 336px;
    left: 130px;
    z-index: 9;
    font-size: 12px;
    color: #ffffff;
    text-size-adjust: 50%;
  }
</style>